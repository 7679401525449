<template>
  <v-card tile>
    <v-toolbar flat color="app-blue" dark class="card-header">
      <span class="text-h5 font-weight-light">
        {{ $t('members.inviteMembers') }}
      </span>
      <v-spacer></v-spacer>
      <v-btn text icon @click="$emit('close')" class="mr-0">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pb-0 pt-6">
      <p class="text-center font-weight-medium">
        {{ $t('members.inviteGroupDescription') }}
      </p>

      <div v-if="isBasicUser">
        <p class="text-center font-weight-medium">
          <strong>{{ $t('common.note') }}: </strong>
          <span>{{ $t('members.cannotAddMoreThan5MembersToGroup') }}</span>
        </p>

        <p class="text-center app-red--text">
          <strong v-if="leftMembersCount > 0">
            {{ $t('members.youCanAddMembers', { count: leftMembersCount }) }}
          </strong>
          <strong v-else>
            {{ $t('members.cannotAddMoreMembers') }}
          </strong>
        </p>
      </div>

      <v-card
        v-for="(email, index) of emails"
        :key="email"
        class="mb-2 py-2"
        tile
      >
        <v-card-title class="py-0">
          <span class="font-weight-medium">{{ email }}</span>
          <v-spacer></v-spacer>
          <v-btn text icon color="app-red" @click="del(index)">
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
        </v-card-title>
      </v-card>
      <v-text-field
        v-if="toggleEmailField"
        ref="emailTextField"
        append-icon="mdi-plus"
        solo
        v-model="model"
        :rules="[rules.email, rules.canAddEmail, rules.canInviteMember]"
        :placeholder="$t('common.email')"
        @click:append="add"
        @keyup.enter="add"
      ></v-text-field>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        v-if="canInvite"
        type="button"
        color="app-blue"
        text
        @click="invite"
        :loading="loading"
      >
        {{ $t('common.invite') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import groupMembersHttpService from '../../services/group.members.http.service';
import validators from '../../common/validators';
import userTypes from '../../enums/user.types';

const MAX_MEMBERS = 5;

export default {
  name: 'invite-members-modal',
  props: {
    members: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      leftMembersCount: MAX_MEMBERS - this.members.length,
      displayError: false,
      model: '',
      emails: [],
      rules: {
        email: validators.isEmail(this.$i18n.t('common.invalidEmail')),
        canAddEmail: validators.customValidator(
          this.canAddEmail,
          this.$i18n.t('members.emailAlreadyAdded')
        ),
        canInviteMember: validators.customValidator(
          this.canInviteMember,
          this.$i18n.t('members.memberAlreadyInGroup')
        )
      }
    };
  },
  computed: {
    ...mapGetters(['currentUser']),
    canInvite() {
      return (
        this.model.length > 0 ||
        this.leftMembersCount > 0 ||
        this.emails.length > 0
      );
    },
    toggleEmailField() {
      return (
        !this.isBasicUser || (this.isBasicUser && this.leftMembersCount > 0)
      );
    },
    isBasicUser() {
      return this.currentUser.type === userTypes.basic;
    }
  },
  methods: {
    canAddEmail(email) {
      if (this.emails.length === 0) return true;

      return this.emails.indexOf(email) === -1;
    },
    canInviteMember(email) {
      return this.members.indexOf(email) === -1;
    },
    reset() {
      if (this.$refs.emailTextField) {
        this.$refs.emailTextField.reset();
      }

      this.model = '';
    },
    add() {
      if (!this.model || !this.$refs.emailTextField.validate()) return;

      this.emails.push(this.model);

      if (this.isBasicUser) {
        this.leftMembersCount--;
      }

      this.reset();
    },
    del(index) {
      this.emails.splice(index, 1);

      if (this.isBasicUser) {
        this.leftMembersCount++;
      }

      if (!this.model) {
        this.reset();
      }
    },
    async invite() {
      if (
        this.model.length > 0 &&
        this.$refs.emailTextField &&
        !this.$refs.emailTextField.valid
      )
        return;

      const payload = [...this.emails];

      if (this.model) {
        payload.push(this.model);
      }

      if (payload.length === 0) return;

      try {
        this.loading = true;
        await groupMembersHttpService.invite(this.$route.params.id, {
          emails: payload
        });

        this.$notify({
          type: 'success',
          text: this.$i18n.t('members.invitationSentSuccessfully')
        });

        this.$emit('close');
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
