<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on }">
      <v-btn icon small text class="app-dark-gray--text mr-0 my-0" v-on="on">
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-if="!isCurrentMemberLeader"
        @click="$emit('promoteToLeader')"
      >
        <v-list-item-title class="app-dark-gray--text">
          <v-icon left class="mr-1">mdi-star-circle</v-icon>
          {{ $t('members.promoteToLeader') }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item v-else @click="$emit('lowerToMember')">
        <v-list-item-title class="app-dark-gray--text">
          <v-icon left class="mr-1">mdi-account</v-icon>
          {{ $t('members.lowerToMember') }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="$emit('sendGems')">
        <v-list-item-title class="app-dark-gray--text">
          <v-icon left class="mr-1">mdi-send</v-icon>
          {{ $t('members.sendGems') }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="$emit('deleteMember')">
        <v-list-item-title class="app-dark-gray--text">
          <v-icon left class="mr-1">mdi-delete</v-icon>
          {{ $t('common.delete') }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'group-member-actions-menu',
  props: {
    isCurrentMemberLeader: {
      type: Boolean,
      required: true
    }
  }
};
</script>
