<template>
  <v-card v-if="member.id" tile class="py-2">
    <v-toolbar flat dense height="30" class="pt-2">
      <v-chip dark label small color="app-green">
        <span class="text-subtitle-1 font-weight-medium">{{ role }}</span>
      </v-chip>
      <v-spacer></v-spacer>
      <group-member-actions-menu
        v-if="canManageMember"
        :isCurrentMemberLeader="isCurrentMemberLeader"
        @promoteToLeader="promoteToLeader"
        @lowerToMember="lowerToMember"
        @sendGems="sendGems"
        @deleteMember="deleteMember"
      ></group-member-actions-menu>
    </v-toolbar>
    <v-container fluid class="text-center pt-5">
      <user-avatar
        :user="member"
        :size="100"
        textSizeClass="text-h4"
      ></user-avatar>
      <v-card-text>
        <span class="text-h6 font-weight-medium">{{ member.name }} </span>
        <div class="mt-2">
          <v-chip
            v-if="isCreatorOrLeader"
            color="app-yellow"
            text-color="white"
            class="pl-2"
          >
            <ruby-label
              :ruby="member.stats.ruby"
              textColor="white---text"
            ></ruby-label>
          </v-chip>
        </div>
      </v-card-text>
      <v-card-actions class="pl-3 py-0">
        <v-btn
          v-if="canManageMember"
          class="mr-3"
          dark
          small
          block
          color="app-blue"
          @click.stop="viewDashboard"
        >
          {{ $t('members.viewDashboard') }}
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import UserAvatar from '../shared/UserAvatar';
import GroupMemberActionsMenu from './GroupMemberActionsMenu';
import RubyLabel from '../shared/RubyLabel';
import ConfirmModal from '../shared/ConfirmModal';
import SendGemsModal from './SendGemsModal';
import {
  PROMOTE_TO_LEADER,
  LOWER_TO_MEMBER,
  SEND_GEMS,
  DELETE_GROUP_MEMBER
} from '../../store/group-members/types';
import groupRoles from '../../enums/group.roles';

export default {
  name: 'group-member-card',
  components: {
    UserAvatar,
    GroupMemberActionsMenu,
    RubyLabel
  },
  props: {
    member: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'isCreatorOrLeader']),
    role() {
      if (this.$i18n.te(`members.roles.${this.member.displayRole}`)) {
        return this.$i18n.t(`members.roles.${this.member.displayRole}`);
      }

      return this.member.displayRole;
    },
    isCurrentMemberLeader() {
      return this.member.groupRole === groupRoles.leader;
    },
    canManageMember() {
      return (
        this.isCreatorOrLeader && this.currentUser.id !== this.member.userId
      );
    }
  },
  methods: {
    viewDashboard() {
      this.$router.push({
        path: `/groups/${this.$route.params.id}/dashboard?userId=${this.member.userId}`
      });
    },
    async promoteToLeader() {
      const confirmed = await this.$root.$modal.show(ConfirmModal, {
        text: this.$i18n.t('members.promoteToLeaderConfirm')
      });

      if (!confirmed) return;

      await this.$store.dispatch(PROMOTE_TO_LEADER, {
        groupId: this.$route.params.id,
        memberId: this.member.id
      });
    },
    async lowerToMember() {
      const confirmed = await this.$root.$modal.show(ConfirmModal, {
        text: this.$i18n.t('members.lowerToMemberConfirm')
      });

      if (!confirmed) return;

      await this.$store.dispatch(LOWER_TO_MEMBER, {
        groupId: this.$route.params.id,
        memberId: this.member.id
      });
    },
    async sendGems() {
      const result = await this.$root.$modal.show(
        SendGemsModal,
        {
          name: this.member.name,
          maxGems: this.currentUser.stats.gems
        },
        {
          width: '360px'
        }
      );

      if (!result) return;

      await this.$store.dispatch(SEND_GEMS, {
        groupId: this.$route.params.id,
        userId: this.member.id,
        gems: result
      });

      this.$notify({
        type: 'success',
        text: this.$i18n.t('members.successfullySentGems')
      });
    },
    async deleteMember() {
      const confirmed = await this.$root.$modal.show(ConfirmModal, {
        text: this.$i18n.t('members.deleteMemberConfirm')
      });

      if (!confirmed) return;

      await this.$store.dispatch(DELETE_GROUP_MEMBER, {
        groupId: this.$route.params.id,
        userId: this.member.id
      });
    }
  }
};
</script>
