<template>
  <v-card tile>
    <v-toolbar flat color="app-blue" dark class="card-header">
      <span class="text-h5 font-weight-light">
        {{ $t('members.sendGems') }}
      </span>
      <v-spacer></v-spacer>
      <v-btn text icon @click="$emit('close')" class="mr-0">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="text-center pb-0 pt-6">
      <h2 class="font-weight-light mb-2">{{ $t('members.youCanSend') }}</h2>
      <v-chip color="app-blue" text-color="white" class="pl-2 gems-label">
        <gems-label :gems="maxGems" textColor="white---text"></gems-label>
      </v-chip>
      <h2 class="font-weight-light mt-2">
        <span class="text-lowercase">{{ $t('common.to') }} </span>
        <span class="font-weight-bold">{{ name }}</span>
      </h2>

      <div class="px-5 mt-4">
        <v-form ref="sendGemsForm">
          <v-row>
            <v-col cols="12">
              <v-text-field
                solo
                v-model="gems"
                :rules="[rules.required, rules.minMaxValue]"
                :label="$t('common.gems')"
                min="1"
                type="number"
                :hint="hint"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-btn dark color="app-blue" block @click="sendGems()">
        {{ $t('members.sendGems') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import validators from '../../common/validators';
import GemsLabel from '../shared/GemsLabel';

const MIN_GEMS = 1;

export default {
  name: 'send-gems-modal',
  components: {
    GemsLabel
  },
  props: {
    name: {
      type: String,
      required: true
    },
    maxGems: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      gems: '',
      hint: this.$t('common.minMaxValue', {
        min: MIN_GEMS,
        max: this.maxGems
      }),
      rules: {
        required: validators.required(this.$i18n.t('common.requiredField')),
        minMaxValue: validators.minMaxValue(
          MIN_GEMS,
          this.maxGems,
          this.$i18n.t('common.minMaxValue', {
            min: MIN_GEMS,
            max: this.maxGems
          })
        )
      }
    };
  },
  methods: {
    sendGems() {
      if (!this.$refs.sendGemsForm.validate()) {
        return;
      }

      this.$emit('close', this.gems);
    }
  }
};
</script>
