<template>
  <v-container v-if="!loading" fluid class="px-1 py-4 mb-10">
    <v-row class="text-right">
      <v-col class="pb-1">
        <v-btn
          v-if="isCreatorOrLeader"
          class="mr-3"
          dark
          :block="$vuetify.breakpoint.xsOnly"
          color="app-blue"
          @click.stop="open"
        >
          <v-icon class="pr-2">mdi-account-multiple-plus</v-icon>
          {{ $t('members.inviteMembers') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        md="3"
        sm="4"
        cols="12"
        v-for="member of groupMembers"
        :key="member.id"
      >
        <group-member-card :member="member"></group-member-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { GET_MEMBERS } from '../../store/group-members/types';
import GroupMemberCard from '../../components/group-members/GroupMemberCard';
import InviteMembersModal from '../../components/group-members/InviteMembersModal';

export default {
  name: 'group-members-page',
  components: {
    GroupMemberCard
  },
  data() {
    return {
      loading: true
    };
  },
  computed: {
    ...mapGetters(['groupMembers', 'isCreatorOrLeader'])
  },
  async created() {
    const { id } = this.$route.params;

    await this.$store.dispatch(GET_MEMBERS, { id });
    this.loading = false;
  },
  methods: {
    open() {
      this.$root.$modal.show(InviteMembersModal, {
        members: this.groupMembers.map(m => m.email)
      });
    }
  }
};
</script>
